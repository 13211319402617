import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

export const translations = {
  en: {
    translation: {
      placeholder: "Prefecture...",
      guess: "Guess",
      share: "Share",
      showOnGoogleMaps: "👀 on Google Maps",
      showOnWikipedia: "📚 on Wikipedia",
      welldone: "Well done!",
      unknownCountry: "Unknown prefecture!",
      copy: "Results copied to clipboard",
      showCountry: "🗺️ Show map!",
      cancelRotation: "🌀 Cancel rotation",
      settings: {
        title: "Settings",
        distanceUnit: "Unit of distance",
        theme: "Theme",
        language: "Language",
        difficultyModifiers: "Difficulty modifiers",
        startingNextDay: "Starting the next day!",
        noImageMode: "Hide prefecture image for more of a challenge.",
        rotationMode: "Randomly rotate prefecture image.",
        updateNotificationDisabled: "Disable update notifications.",
        showScale: "Replace proximity percent by size percent.",
      },
      stats: {
        title: "Statistics",
        played: "Played",
        win: "Win %",
        currentStreak: "Current Streak",
        maxStreak: "Max Streak",
        averageBestDistance: "Best Distances Average",
        guessDistribution: "Guess distribution:",
      },
      install: {
        title: "Japanre",
        descritpionTitle: "App Install:",
        description: "Add Japanre to Home Screen to play it easily!",
        instructionTitle: "Instructions:",
        instructionActionOk: "OK",
        instructionActionCancel: "Cancel",
        instructionActionInstall: "Install",
        instructionFirefoxAction1: "- open browser options ",
        instructionFirefoxAction2: "- add to Home Screen",
        instructionFirefoxNewAction1: "- open browser options ",
        instructionFirefoxNewAction2: '- select "Install"',
        instructionIdeviceAction1: "- on Safari, open the Share menu ",
        instructionIdeviceAction2: '- select "Add to Home Screen"',
        instructionOperaAction1: "- press the menu button ",
        instructionOperaAction2: "- add to Home Screen",
        instructionNotSupported: "Not supported by this browser.",
      },
      support: {
        UA: "Support the Ukrainian Red Cross",
      },
      newVersion: "New version available!",
      update: "Update",
      or: "or",
      ignore: "Ignore",
      adaptedFrom: "Adapted from",
      news: {
        newsNotifications: "News notifications system added!",
      },
    },
  },
  ja: {
    translation: {
      placeholder: "県...",
      guess: "推測",
      share: "シェア",
      showOnGoogleMaps: "Googleマップで👀",
      showOnWikipedia: "ウィキペディアで📚",
      welldone: "素晴らしい！",
      unknownCountry: "不明県！",
      copy: "結果がクリップボードにコピーされました",
      showCountry: "🗺️ 地図を表示！",
      cancelRotation: "🌀 ローテーションをキャンセル",
      settings: {
        title: "設定",
        distanceUnit: "距離の単位",
        theme: "テーマ",
        language: "言語",
        difficultyModifiers: "難易度モディファイア",
        startingNextDay: "翌日から！",
        noImageMode: "もっと挑戦するために県の画像を隠す。",
        rotationMode: "県の画像をランダムに回転させる。",
        updateNotificationDisabled: "更新通知を無効にする。",
        showScale: "Replace proximity percent by size percent.",
      },
      stats: {
        title: "統計学",
        played: "再生回数",
        win: "勝つ %",
        currentStreak: "現在のストリーク",
        maxStreak: "マックスストリーク",
        averageBestDistance: "最高距離平均",
        guessDistribution: "分布を推測する:",
      },
      install: {
        title: "JAPANRE",
        descritpionTitle: "アプリのインストール：",
        description: "ホーム画面にJAPANREを追加すると、簡単に再生できます。",
        instructionTitle: "指示：",
        instructionActionOk: "わかった",
        instructionActionCancel: "キャンセル",
        instructionActionInstall: "インストール",
        instructionFirefoxAction1: "- ブラウザオプションを開く ",
        instructionFirefoxAction2: "- ホーム画面に追加します",
        instructionFirefoxNewAction1: "- ブラウザオプションを開く ",
        instructionFirefoxNewAction2: "- 「インストール」を選択します",
        instructionIdeviceAction1: "- Safariで、[共有]メニューを開きます ",
        instructionIdeviceAction2: "- ホーム画面に追加」を選択します",
        instructionOperaAction1: "- メニューボタンを押す ",
        instructionOperaAction2: "-  ホーム画面に追加します",
        instructionNotSupported: "このブラウザではサポートされていません。",
      },
      support: {
        UA: "ウクライナ赤十字を支援する",
      },
      newVersion: "新しいバージョンが利用可能!",
      update: "アップデート",
      or: "または",
      ignore: "無視",
      adaptedFrom: "から適応",
      news: {
        newsNotifications: "ニュース通知システムを追加！",
      },
    },
  },
  fr: {
    translation: {
      placeholder: "Préfecture...",
      guess: "Deviner",
      share: "Partager",
      showOnGoogleMaps: "👀 sur Google Maps",
      showOnWikipedia: "📚 sur Wikipedia",
      welldone: "Bien joué !",
      unknownCountry: "Préfecture inconnue !",
      copy: "Résultat copié !",
      showCountry: "🗺️ Afficher la carte !",
      cancelRotation: "🌀 Annule la rotation",
      settings: {
        title: "Paramètres",
        distanceUnit: "Unité de distance",
        theme: "Thème",
        language: "Langage",
        difficultyModifiers: "Modificateurs de difficulté",
        startingNextDay: "A partir du lendemain !",
        noImageMode: "Cache l'image de la préfecture pour plus de challenge.",
        rotationMode: "Tourne l'image de la préfecture de manière aléatoire.",
        updateNotificationDisabled:
          "Désactive les notification de mise à jour.",
        showScale:
          "Remplace le pourcentage de proximité par le pourcentage de taille.",
      },
      stats: {
        title: "Statistiques",
        played: "Parties",
        win: "Victoires %",
        currentStreak: "Série Actuelle",
        maxStreak: "Série Max",
        averageBestDistance: "Moyenne Meilleures Distances",
        guessDistribution: "Répartitions des victoires:",
      },
      install: {
        title: "Japanre",
        descritpionTitle: "Installer l'app:",
        description:
          "Ajouter Japanre sur l'écran d'accueil pour le retrouver plus facilement !",
        instructionTitle: "Instructions :",
        instructionActionOk: "OK",
        instructionActionCancel: "Annuler",
        instructionActionInstall: "Installer",
        instructionFirefoxAction1: "- ouvrir les options du navigateur ",
        instructionFirefoxAction2: "- ajouter à votre écran d'accueil",
        instructionFirefoxNewAction1: "- ouvrir les options du navigateur ",
        instructionFirefoxNewAction2: '- sélectionner "Installer"',
        instructionIdeviceAction1: "- sur Safari, ouvrir le menu de partage ",
        instructionIdeviceAction2: "- sélectionner \"Sur l'écran d'accueil\"",
        instructionOperaAction1: "- appuyer sur le bouton menu ",
        instructionOperaAction2: "- ajouter à l'écran d'accueil",
        instructionNotSupported: "Impossible sur ce navigateur.",
      },
      support: {
        UA: "Soutenez la Croix Rouge Ukrainienne",
      },
      newVersion: "Nouvelle version disponible !",
      update: "Mettre à jour",
      or: "ou",
      ignore: "Ignorer",
      adaptedFrom: "Adapté de",
      news: {},
    },
  },
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: translations,
    interpolation: {
      escapeValue: false,
    },
    fallbackLng: "en",
  });

export default i18n;
