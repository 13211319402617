export const areas: Record<string, number> = {
  1: 83424,
  2: 9646,
  3: 15275,
  4: 7282,
  5: 11638,
  6: 9323,
  7: 13784,
  8: 6097,
  9: 6408,
  10: 6362,
  11: 3798,
  12: 5158,
  13: 2191,
  14: 2416,
  15: 12584,
  16: 4248,
  17: 4186,
  18: 4190,
  19: 4465,
  20: 13562,
  21: 10621,
  22: 7777,
  23: 5172,
  24: 5774,
  25: 4017,
  26: 4612,
  27: 1905,
  28: 8401,
  29: 3691,
  30: 4725,
  31: 3507,
  32: 6708,
  33: 7115,
  34: 8479,
  35: 6112,
  36: 4147,
  37: 1877,
  38: 5676,
  39: 7104,
  40: 4986,
  41: 2441,
  42: 4132,
  43: 7409,
  44: 6341,
  45: 7735,
  46: 9187,
  47: 2281,
};
