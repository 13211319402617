import { Country } from "./countries";

export const countries: Country[] = [
  {
    code: "1",
    latitude: 43.06417,
    longitude: 141.34694,
    name: "Hokkaido",
  },
  {
    code: "2",
    latitude: 40.82444,
    longitude: 140.74,
    name: "Aomori",
  },
  {
    code: "3",
    latitude: 39.70361,
    longitude: 141.1525,
    name: "Iwate",
  },
  {
    code: "4",
    latitude: 38.26889,
    longitude: 140.87194,
    name: "Miyagi",
  },
  {
    code: "5",
    latitude: 39.71861,
    longitude: 140.1025,
    name: "Akita",
  },
  {
    code: "6",
    latitude: 38.24056,
    longitude: 140.36333,
    name: "Yamagata",
  },
  {
    code: "7",
    latitude: 37.75,
    longitude: 140.46778,
    name: "Fukushima",
  },
  {
    code: "8",
    latitude: 36.34139,
    longitude: 140.44667,
    name: "Ibaraki",
  },
  {
    code: "9",
    latitude: 36.56583,
    longitude: 139.88361,
    name: "Tochigi",
  },
  {
    code: "10",
    latitude: 36.39111,
    longitude: 139.06083,
    name: "Gunma",
  },
  {
    code: "11",
    latitude: 35.85694,
    longitude: 139.64889,
    name: "Saitama",
  },
  {
    code: "12",
    latitude: 35.60472,
    longitude: 140.12333,
    name: "Chiba",
  },
  {
    code: "13",
    latitude: 35.68944,
    longitude: 139.69167,
    name: "Tokyo",
  },
  {
    code: "14",
    latitude: 35.44778,
    longitude: 139.6425,
    name: "Kanagawa",
  },
  {
    code: "15",
    latitude: 37.90222,
    longitude: 139.02361,
    name: "Niigata",
  },
  {
    code: "16",
    latitude: 36.69528,
    longitude: 137.21139,
    name: "Toyama",
  },
  {
    code: "17",
    latitude: 36.59444,
    longitude: 136.62556,
    name: "Ishikawa",
  },
  {
    code: "18",
    latitude: 36.06528,
    longitude: 136.22194,
    name: "Fukui",
  },
  {
    code: "19",
    latitude: 35.66389,
    longitude: 138.56833,
    name: "Yamanashi",
  },
  {
    code: "20",
    latitude: 36.65139,
    longitude: 138.18111,
    name: "Nagano",
  },
  {
    code: "21",
    latitude: 35.39111,
    longitude: 136.72222,
    name: "Gifu",
  },
  {
    code: "22",
    latitude: 34.97694,
    longitude: 138.38306,
    name: "Shizuoka",
  },
  {
    code: "23",
    latitude: 35.18028,
    longitude: 136.90667,
    name: "Aichi",
  },
  {
    code: "24",
    latitude: 34.73028,
    longitude: 136.50861,
    name: "Mie",
  },
  {
    code: "25",
    latitude: 35.00444,
    longitude: 135.86833,
    name: "Shiga",
  },
  {
    code: "26",
    latitude: 35.02139,
    longitude: 135.75556,
    name: "Kyoto",
  },
  {
    code: "27",
    latitude: 34.68639,
    longitude: 135.52,
    name: "Osaka",
  },
  {
    code: "28",
    latitude: 34.69139,
    longitude: 135.18306,
    name: "Hyōgo",
  },
  {
    code: "29",
    latitude: 34.68528,
    longitude: 135.83278,
    name: "Nara",
  },
  {
    code: "30",
    latitude: 34.22611,
    longitude: 135.1675,
    name: "Wakayama",
  },
  {
    code: "31",
    latitude: 35.50361,
    longitude: 134.23833,
    name: "Tottori",
  },
  {
    code: "32",
    latitude: 35.47222,
    longitude: 133.05056,
    name: "Shimane",
  },
  {
    code: "33",
    latitude: 34.66167,
    longitude: 133.935,
    name: "Okayama",
  },
  {
    code: "34",
    latitude: 34.39639,
    longitude: 132.45944,
    name: "Hiroshima",
  },
  {
    code: "35",
    latitude: 34.18583,
    longitude: 131.47139,
    name: "Yamaguchi",
  },
  {
    code: "36",
    latitude: 34.06583,
    longitude: 134.55944,
    name: "Tokushima",
  },
  {
    code: "37",
    latitude: 34.34028,
    longitude: 134.04333,
    name: "Kagawa",
  },
  {
    code: "38",
    latitude: 33.84167,
    longitude: 132.76611,
    name: "Ehime",
  },
  {
    code: "39",
    latitude: 33.55972,
    longitude: 133.53111,
    name: "Kōchi",
  },
  {
    code: "40",
    latitude: 33.60639,
    longitude: 130.41806,
    name: "Fukuoka",
  },
  {
    code: "41",
    latitude: 33.24944,
    longitude: 130.29889,
    name: "Saga",
  },
  {
    code: "42",
    latitude: 32.74472,
    longitude: 129.87361,
    name: "Nagasaki",
  },
  {
    code: "43",
    latitude: 32.78972,
    longitude: 130.74167,
    name: "Kumamoto",
  },
  {
    code: "44",
    latitude: 33.23806,
    longitude: 131.6125,
    name: "Ōita",
  },
  {
    code: "45",
    latitude: 31.91111,
    longitude: 131.42389,
    name: "Miyazaki",
  },
  {
    code: "46",
    latitude: 31.56028,
    longitude: 130.55806,
    name: "Kagoshima",
  },
  {
    code: "47",
    latitude: 26.2125,
    longitude: 127.68111,
    name: "Okinawa",
  },
];
